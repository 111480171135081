import Approachh from '../../assets/approach.jpg';
export default function Approach() {
  return (
    <>
    {/* <div className="banner-space"></div> */}
    <div className='black-bg20'>&nbsp;</div>
    <div className="row" >
      <div className='col-md-1'></div>
      <div className="image-section col-md-4" data-aos="fade-right">
        <img
          src={Approachh}
          alt="Leader Consulting"
          className="leader-image"
        />
      </div>
      
      <div className="text-section col-md-6 pt-3" data-aos="fade-left">
        <h1>Customer<br />Focused Approach</h1>
        <p>We prioritize customer satisfaction above all else. From the very first interaction with our team to the moment buyers step into their dream home, we ensure a seamless and stress-free journey every step of the way.
        </p>
        <p>Our core values include open communication, unwavering transparency, and a proactive approach to addressing customer needs and concerns.</p>
        {/* <a href="#" className="read-more-link">Read More&nbsp;<i class="bi bi-arrow-down-circle"></i></a> */}
       
      </div>
    </div>
    
    </>
  );
}
