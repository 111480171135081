import Shapping from '../../assets/shapping.jpg';
export default function Shaping() {
  return (
    <>
      {/* <div className="banner-space"></div> */}
      <div className='black-bg20'>&nbsp;</div>
      <div className="row" >
        <div className='col-md-1'></div>

        <div className="text-section col-md-6 pt-5" data-aos="fade-right">
          <h1 className='py-5'>Shaping a <br />
          Sustainable <br />
          Future<br />
          </h1>
          <p>At Ranav Group, we believe that true progress is achieved when innovation and responsibility go hand in hand. Our commitment to sustainability drives us to design spaces that not only offer luxurious living but also contribute to the well-being of our planet. By incorporating eco-friendly practices, energy-efficient technologies, and sustainable materials, we strive to create a greener tomorrow while enhancing the quality of life for generations to come.</p>
          <p>Our homes are not just investments—they are sanctuaries, thoughtfully designed to enrich your life today and stand the test of time for generations to come.</p>
          {/* <a href="#" className="read-more-link">Read More&nbsp;<i class="bi bi-arrow-down-circle"></i></a> */}
         
        </div>
        <div className="image-section col-md-4" data-aos="fade-left">
          <img
            src={Shapping}
            alt="Leader Consulting"
            className="leader-image"
          />
        </div>
      </div>

    </>
  );
}
