import Promises from '../../assets/lifestyle.png';

export default function LifeStyle() {
    return (
        <section className="promise-section container-fluid text-white py-5">
            <div className="container row align-items-center">
                {/* Text Content */}
                <div className='col-md-1 col-sm-12 pl-md-5'></div>
                <div data-aos="fade-right" className="col-md-11 col-sm-12">
                    {/* <h6 className="small-heading text-uppercase">A Gateway to</h6> */}
                    
                    <h1 className="yellow-text pb-3">EXCLUSIVE HIGH-END LIFESTYLE</h1>
                    {/* <h6 className="small-heading text-uppercase">Superior Living Spaces with Unmatched Quality at Exceptional Value!</h6> */}
                    
                    <p>
                    Ranav Group redefines luxury living by offering an unparalleled lifestyle experience. With the finest amenities and thoughtfully designed spaces, we ensure you enjoy a perfect ambiance every day.
                    </p>
                    <p>
                        It’s no surprise that our customers feel at home even before moving in—because we don’t just build structures, 
                        we bring their dreams to life. This is the unwavering promise of Ranav Group.
                        Our projects are crafted to deliver maximum happiness per square foot, featuring exceptional lifestyle features and world-class amenities tailored for our valued homebuyers.
                    </p>
                    <p>
                    Experience the essence of luxury like never before with Ranav Group.
                    </p>
                </div>
                <div className='col-md-1 col-sm-12 pl-md-5'></div>
                <div data-aos="zoom-out" className="col-md-11 col-sm-12">
                <img src={Promises} alt="Ranav Group Promise" className="img-fluid rounded" />
                </div>
            </div>
        </section>
    );
}
