import { useState } from 'react';
import HomeBannerImage from '../../assets/banners/banner1.png';

export default function HomeBanner() {
  const [videoLoaded, setVideoLoaded] = useState(false);

  return (
    <div className="video-banner-container position-relative pt-5">
      {/* Thumbnail Image */}
      {!videoLoaded && (
        <img
          src={HomeBannerImage}
          alt="Banner Thumbnail"
          className="video-thumbnail w-100 h-100 position-absolute top-20 start-0"
        />
      )}

      {/* New Video */}
      <video
        src="https://ranavgroup.com/video/main.mp4"
        className="video-banner w-100 h-100 position-absolute top-2 start-0 pt-5"
        autoPlay
        muted
        loop
        onCanPlay={() => setVideoLoaded(true)} // Hides the thumbnail when video loads
        playsInline
      ></video>

      {/* Overlay Text */}
      <div data-aos="zoom-in" className="video-banner-overlay position-absolute top-0 start-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center text-white">
        <h1 className="fw-bold display-3" data-aos="zoom-in">WELCOME TO RANAV GROUP</h1> 
         <h4 className="mt-3">Dreams Refined into Reality</h4>
      </div>
    </div>
  );
}
