import Promises from '../../assets/visionn.png';
import Mission from '../../assets/missionn.png';
import Value from '../../assets/valuess.png';

export default function VisionaryDesign() {
    return (
        <section className="promise-section container-fluid text-white py-5">
            <div className="container row align-items-center">
                {/* Text Content */}
                <div className='col-md-4 col-sm-12 pl-md-5'></div>
                <div data-aos="fade-right" className="col-md-8 col-sm-12">
                    {/* <h6 className="small-heading text-uppercase">A Gateway to</h6> */}
                    
                    <h1 className="yellow-text pb-3 text-end">Visionary Design</h1>
                    {/* <h6 className="small-heading text-uppercase">Superior Living Spaces with Unmatched Quality at Exceptional Value!</h6> */}
                    
                    <p>
                    At Ranav Group, we are committed to creating spaces and structures that inspire, elevate, and enrich lives.</p>
                    <p>All our projects are crafted with a deep passion for architecture, seamlessly blending modern design with practicality to create spaces that inspire and function flawlessly
                    </p>
                    {/* <p>
                        It’s no surprise that our customers feel at home even before moving in—because we don’t just build structures, 
                        we bring their dreams to life. This is the unwavering promise of Ranav Group.
                    </p> */}
                </div>
                <div className='col-md-1 col-sm-12 pl-md-5'></div>
                <div data-aos="zoom-out" className="col-md-10 col-sm-12">
                <img src={Promises} alt="Ranav Group Promise" className="img-fluid rounded" />
                </div>
                <div className='col-md-1 col-sm-12 pl-md-5'></div>
            </div>
        </section>
    );
}
