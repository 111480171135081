import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/ranav_logo.png";
const phone = "tel:08026662666";

export default function NavBbar() {
  const location = useLocation();
  const getActiveClass = (path) => (location.pathname === path ? "active" : "");
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light" id="top">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className={`nav-link ${getActiveClass("/")}`} to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${getActiveClass("/about")}`}
                to="/about"
              >
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${getActiveClass("/projects")}`}
                to="/projects"
              >
                Projects
              </Link>
            </li>

            {/* <li className="nav-item dropdown">
              <Link
                className={`nav-link ${getActiveClass("/projects")}`}
                to="/projects"
                
                id="projectsDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Projects
              </Link>
              <ul className="dropdown-menu" aria-labelledby="projectsDropdown">
                <li>
                  <Link className="dropdown-item" >
                   <a href="/projects/#solutions" >
                   Our Solutions
                   </a>
                    </Link>
                    </li>
                <li>
                  <Link className="dropdown-item" to="/projects/#ongoing">
                    On Going Projects
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/projects/#upcoing">
                    Up Coming Projects
                  </Link>
                </li>
                </ul>

            </li> */}
            
            {/* <li className="nav-item">
              <Link
                className={`nav-link ${getActiveClass("/gallery")}`}
                to="/gallery"
              >
                Gallery
              </Link>
            </li> */}
            <li className="nav-item">
              <Link
                className={`nav-link ${getActiveClass("/contact")}`}
                to="/contact"
              >
                Contact
              </Link>
            </li>
            <li className="nav-item ms-3">
              <a className="nav-link" href={phone}>
                <i className="bi bi-telephone"></i> 080 2666 2666
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
