import Visions from '../../assets/vision.jpg';

export default function Vision() {
    return (
        <section className="Vision-section container-fluid text-white py-5">
            <div className="container row align-items-center">
                {/* Text Content */}
                <div className='col-md-1'></div>
                <div data-aos="zoom-out" className="col-md-5 col-12">
                <img src={Visions} alt="Ranav Group Vision" className="img-fluid rounded" />
                </div>
                <div className='col-md-1 col-sm-12'></div>
                <div data-aos="fade-right" className="col-md-5 col-sm-12">
                    <h1 className="yellow-text pb-3">VISION</h1>
                    <p>
                    Apart from focus, vision, and dedication, there is another essential trait that every business organization must possess today a resilient and success-driven mindset. This is a quality that Team Ranav has cultivated over the years through an unwavering commitment to company goals and an immense appetite for success.

                    </p>
                    <p>
                    Every team member begins their day with a positive attitude, channeling boundless energy into creative thinking and an innovative mindset. This approach ensures we consistently deliver excellence and exceed expectations.
                    </p>
                </div>
                
            </div>
        </section>
    );
}
