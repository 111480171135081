import Solution1 from "../../assets/solution1.png"
import Solution2 from "../../assets/solution2.png"


export default function Solutions() {
    return (
        <div className="testimonial-section text-center py-5">
            <h1 data-aos="fade-down" className="yello-text mb-3 pt-5" ><br /><br />OUR SOLUTIONS</h1>
           
            <div className="text-center text-white flex d-flex justify-content-center">
            
          <div className="tall" data-aos="fade-right">
            <img src={Solution1} alt="" />
            <h3>LUXURIOUS VILLAS</h3>
          </div>
          <div className="tall" data-aos="fade-left">
            <img src={Solution2} alt="" />
            <h3>PREMIUM APPARTMENTS</h3>
          </div>
        </div>
        </div>
    );
}
