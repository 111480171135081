import Promises from '../../assets/why.png';

export default function WhyRanav() {
    return (
        <section className="promise-section container-fluid text-white py-5">
            <div className="container row align-items-center">
                {/* Text Content */}
                <div className='col-md-1 col-sm-12'></div>
                <div data-aos="fade-right" className="col-md-7 col-sm-12">

                    {/* <h6 className="small-heading text-uppercase">Your Dream Home</h6> */}
                    <h1 className="yellow-text pb-3">Why Ranav Group?</h1>
                    <h6 className="small-heading text-uppercase">Luxurious Lifestyle Made Affordable!<br /><br /></h6>
                    <p>
                        <b>Quality: </b><br />
                        We uphold uncompromising standards of quality in every project we undertake. From selecting the finest materials to executing flawless designs, we ensure that every aspect of construction reflects excellence.
                    </p>
                    <p>
                        <b>Innovation:</b><br />
                        Innovation is at the heart of our philosophy. We continuously integrate the latest trends and technologies into our projects, enhancing the living and working experiences of our clients.</p>
                    <p><b>Transparency:</b><br />
                        We believe in maintaining open and honest communication with our clients. From project inception to completion, we keep you informed about milestones, timelines, and budgets, fostering trust and providing peace of mind.</p>
                    <p><b>Sustainability:</b>
                        <br />As responsible builders, we are committed to sustainable practices that minimize our environmental footprint. Our projects are designed to be energy efficient and eco-friendly, contributing to a healthier, greener future.
                    </p>
                    <p>
                    <b>Customer Satisfaction:</b><br />
                    Our ultimate goal is the satisfaction of our clients. We prioritize your needs and aspirations, ensuring a seamless and rewarding journey from the initial consultation to the project's final completion.
                    </p>
                </div>
                {/* <div className='col-md-1'></div> */}
                <div data-aos="zoom-out" className="col-md-4 col-12">
                    <img src={Promises} alt="Ranav Group Promise" className="img-fluid rounded" />
                </div>
            </div>
        </section>
    );
}
