import Header from "../components/header";
import Footer from "../components/footer";
import ContactNav from "../components/contact-nav";
import Subscription from "../components/subscription";
import AboutContent from "../components/PageComponents/AboutContnet";
import TrustRanav from "../components/PageComponents/TrustRanav";
import FeaturedCategories from "../components/PageComponents/FeaturedCategories";
import Testimonials from "../components/PageComponents/Testimonials"
import BestProperties from "../components/PageComponents/BestProperties"
import MapComponent from "../components/PageComponents/MapComponent";
import LeaderConsulting from "../components/PageComponents/LeaderConsulting";
import AboutBanner from "../components/Banners/About_video";
import DreamLive from "../components/PageComponents/DreamLive";
import RanavTeam from "../components/PageComponents/RanavTeam";
import VisionMissionValues from "../components/PageComponents/VisionMissionValues";
import Shaping from "../components/PageComponents/Shaping";
import Approach from "../components/PageComponents/Approach";
import Solutions from "../components/PageComponents/Solutions";


export default function About(){
    return(
        <>
                <Header/>
                <AboutBanner />
                <ContactNav />
                <DreamLive />
                <LeaderConsulting />
                {/* <RanavTeam /> */}
                <VisionMissionValues />
                <Shaping />
                <Approach />
                <Solutions />

                {/* <AboutContent /> */}
                {/* <TrustRanav /> */}
                <FeaturedCategories />
                {/* <Testimonials /> */}
                {/* <BestProperties /> */}
                {/* home page body part */}
                <MapComponent />
                <Subscription />
                <Footer />
        </>
    )
}