import RanavLeader from '../../assets/founder.jpg';
export default function LeaderConsulting() {
  return (
    <>
    {/* <div className="banner-space"></div> */}
    <div className='black-bg20'>&nbsp;</div>
    <div className="row" >
      <div className='col-md-1'></div>
      <div className="image-section col-md-4" data-aos="fade-right">
        <img
          src={RanavLeader}
          alt="Leader Consulting"
          className="leader-image"
          data-aos="zoom-in"
        />
      </div>
      
      <div className="text-section col-md-6 pt-3" data-aos="fade-right">
        <h1 data-aos="zoom-out">PURPOSE<br />PASSION <br />PERSISTANCE </h1>
        <p data-aos="zoom-in">With a B.Tech in Engineering, Mr. Naveen Naidu set out in 2006 to make a lasting impact on people's lives. Starting in the transportation sector, he laid strong foundations before venturing into real estate in 2012 with a vision to elevate middle-class lifestyles.
        </p>
        <p data-aos="zoom-in">As a hands-on leader, he introduced ethical business practices and spearheaded transformative projects. In 2021, he founded Ranav Group, dedicated to creating homes that enrich lives, adapt to evolving needs, and deliver lasting value for generations.</p>
        {/* <a href="#" className="read-more-link">Read More&nbsp;<i class="bi bi-arrow-down-circle"></i></a> */}
        <h2 data-aos="fade-up">Naveen Naidu</h2>
        <h3 data-aos="fade-up">Founder & Director</h3>
      </div>
    </div>
    
    </>
  );
}
