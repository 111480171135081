import Promises from '../../assets/offer.png';

export default function Offer() {
    return (
        <section className="promise-section container-fluid text-white py-5">
            <div className="container row align-items-center">
                {/* Text Content */}
                <div className='col-md-1 col-sm-12 pl-md-5'></div>
                <div data-aos="fade-right" className="col-md-11 col-sm-12">
                    {/* <h6 className="small-heading text-uppercase">A Gateway to</h6> */}
                    
                    <h1 className="yellow-text pb-3">WE PROUDLY OFFER</h1>
                    <h6 className="small-heading text-uppercase">Superior Living Spaces with Unmatched Quality at Exceptional Value!</h6>
                    
                    <p>
                    Ranav Group is synonymous with unparalleled quality. From concept to completion, we uphold an unwavering commitment to superior craftsmanship, premium materials, and meticulous attention to detail. Every project is built to the highest standards, utilizing world-class brands and top-quality materials to create exceptional spaces that stand the test of time.
                    </p>
                    {/* <p>
                        It’s no surprise that our customers feel at home even before moving in—because we don’t just build structures, 
                        we bring their dreams to life. This is the unwavering promise of Ranav Group.
                    </p> */}
                </div>
                <div className='col-md-1 col-sm-12 pl-md-5'></div>
                <div data-aos="zoom-out" className="col-md-11 col-sm-12">
                <img src={Promises} alt="Ranav Group Promise" className="img-fluid rounded" />
                </div>
            </div>
        </section>
    );
}
