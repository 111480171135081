import RanavLeader from '../../assets/about1.jpg';
export default function DreamLive() {
  return (
    <>
      {/* <div className="banner-space"></div> */}
      <div className='black-bg20'>&nbsp;</div>
      <div className="row" >
        <div className='col-md-1'></div>


        <div className="text-section col-md-6 pt-5" data-aos="fade-right">
          <h1 className='py-5' data-aos="zoom-out">DREAM IT...<br />
            LIVE IT...<br />
            WE<br />
            MAKE IT REAL<br />
          </h1>
          <p data-aos="zoom-in">At RANAV GROUP, we are dedicated to creating homes that elevate your lifestyle and seamlessly adapt to your evolving needs. Every project we undertake is a testament to our commitment to quality, value, and innovation.
          </p>
          <p data-aos="zoom in">Our homes are not just investments—they are sanctuaries, thoughtfully designed to enrich your life today and stand the test of time for generations to come.</p>
          {/* <a href="#" className="read-more-link">Read More&nbsp;<i class="bi bi-arrow-down-circle"></i></a> */}
         
        </div>

        <div className="image-section col-md-4" data-aos="fade-left">
          <img
            src={RanavLeader}
            alt="Leader Consulting"
            className="leader-image"
            data-aos="zoom-in"
          />
        </div>
      </div>

    </>
  );
}
