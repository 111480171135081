import vissionn from '../../assets/visionn.png';
import missionn from '../../assets/missionn.png';
import valuee from '../../assets/valuess.png';
export default function VisionMissionValues() {
  return (
    <>
      {/* <div className="banner-space"></div> */}
      <div className='black-bg20'>&nbsp;</div>
      <div className="row" >
        <div className='col-md-1'></div>
        <div className="text-section col-md-7 py-3" data-aos="fade-right">
          <h1 className=''>VISION</h1>
          <p>"To redefine luxury living with groundbreaking
            innovation and unmatched quality, creating spaces
            that inspire and elevate lives."
          </p>
          {/* <a href="#" className="read-more-link">Read More&nbsp;<i class="bi bi-arrow-down-circle"></i></a> */}

        </div>

        <div className="image-section col-md-4" data-aos="fade-left">
          <img
            src={vissionn}
            alt="Leader Consulting"
            className="leader-image"
          />
        </div>
      </div>
      <div className="row" >
        <div className='col-md-1'></div>
        <div className="image-section col-md-4" data-aos="fade-right">
          <img
            src={missionn}
            alt="Leader Consulting"
            className="leader-image"
          />
        </div>
        <div className="text-section col-md-7 py-3" data-aos="fade-left">
          <h1 className=''>MISSION</h1>
          <p>"At Ranav Group, Our mission is to craft exceptional
            living and working environments through collaborative
            innovation and ethical practices. We partner with
            like-minded stakeholders to tackle real estate challenges
            with integrity and efficiency. Trust us to transform your
            dreams into tangible realities, creating spaces where life
            and work truly thrive."</p>
          {/* <a href="#" className="read-more-link">Read More&nbsp;<i class="bi bi-arrow-down-circle"></i></a> */}

        </div>
      </div>

      <div className="row" >
        <div className='col-md-1'></div>
        <div className="text-section col-md-7 py-3" data-aos="fade-right">
          <h1 className=''>VALUES</h1>
          <p>"At Ranav Group, Our mission is to craft exceptional
            living and working environments through collaborative
            innovation and ethical practices. We partner with
            like-minded stakeholders to tackle real estate challenges
            with integrity and efficiency. Trust us to transform your
            dreams into tangible realities, creating spaces where life
            and work truly thrive."

          </p>
          {/* <a href="#" className="read-more-link">Read More&nbsp;<i class="bi bi-arrow-down-circle"></i></a> */}

        </div>

        <div className="image-section col-md-4" data-aos="fade-left">
          <img
            src={valuee}
            alt="Leader Consulting"
            className="leader-image"
          />
        </div>
      </div>

    </>
  );
}
